import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProjectBanner from '../../components/projectBanner';

const PortfolioCobox = () => {
  const data = useStaticQuery(graphql`
    query {
      coboxImg: file(relativePath: { eq: "cobox.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      mobileApp: file(relativePath: { eq: "coboxMobileApp.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      adminDashboard: file(relativePath: { eq: "coboxAdminDashboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout pageInfo={{ pageName: 'cobox' }} noLayoutbanner>
      <SEO title="Cobox | Projects" />
      <ProjectBanner bgColor="#003bff" title="Cobox" description="Your one stop destination for shopping discounts in middle east." image={data.coboxImg.childImageSharp.fluid} />
      <TheProject />
      <TheMobileApp image={data.mobileApp.childImageSharp.fluid} />
      <AdminDashboard image={data.adminDashboard.childImageSharp.fluid} />
    </Layout>
  );
};

const TheProject = () => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5">
        <Col lg={4} xs={12}>
          <p className="text-left h4 mb-1 font-weight-bolder">The Project</p>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" style={{ width: '100px' }} />
        </Col>
        <Col lg={8} xs={12}>
          <p style={{ width: '100%' }}>Cobox is a discount coupon aggregator, based in the middle east. We build mobile apps in iOS and Android which helps users to discover discount coupons from leading online stores across the middle east.</p>
        </Col>
      </Row>
    </Container>
  );
};

const TheMobileApp = ({ image }) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5 align-items-md-center">
        <Col xs="12" lg="8">
          <Img className="cobox-mobileapp-img headshot w-100 ml-auto mr-auto d-block" fluid={image} alt="" />
        </Col>
        <Col xs="12" lg="4">
          <p className="text-left h4 mb-1 font-weight-bolder">The Mobile App</p>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
          <p style={{ width: '100%' }}>Online stores often advertises tempting discount coupons from time to time. Cobox helps users discover the best coupons from across several stores and allow you to use and share those coupons with your friends</p>
        </Col>
      </Row>
    </Container>
  );
};

const AdminDashboard = ({ image }) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5 align-items-md-center">
        <Col lg={12} xs={12}>
          <p className="text-left text-md-center h4 mb-1 font-weight-bolder">Admin Dashboard</p>
          <hr className="mx-0 d-flex info-color mb-4 mt-0 d-inline-block mx-md-auto hr-styles" />
          <p className="text-md-center text-left mt-0  mx-auto w-100 w-lg-75">The success of the app depends on providing users with latest and valid coupon codes from a wide range of online stores. A fully functional admin dashboard for adding, scheduling, managing coupons and stores made this process easy to manage.</p>
          <Img className="headshot w-100 mx-auto d-block" fluid={image} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default PortfolioCobox;
